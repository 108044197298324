import React, { useContext, useState } from 'react';
import {
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   ListItemSecondaryAction,
   Divider,
   Typography,
   FormControl,
   InputLabel,
   Select,
   MenuItem
} from '@material-ui/core';
import AppContext from './AppContext';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles(theme => {
   return {
      score: {
         textAlign: 'center'
      },
      formControl: {
         width: '100%',
         marginTop: theme.spacing(2),
         marginBottom: theme.spacing(2)
      },
      scored: {
         color: theme.palette.primary.main
      },
      unscored: {
         opacity: '0.5'
      },
      text: {
         color: theme.palette.text.primary
      }
   };
});

export default function ActionList() {
   const appContext = useContext(AppContext);
   const { data } = appContext;
   const [team, setTeam] = useState('');
   const classes = useStyles();
   const teams = ['Aquile', 'Castori', 'Panda', 'Pantere'];
   const teamIndex = team ? teams.findIndex(t => t === team) + 2 : null;

   return (
      <>
         <Typography variant="h4" className={classes.text} align="center">
            Sfide
         </Typography>
         <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Squadriglia</InputLabel>
            <Select
               value={team}
               onChange={e => {
                  setTeam(e.target.value);
               }}
               fullWidth
            >
               <MenuItem value="">
                  <em>Seleziona una squadriglia</em>
               </MenuItem>
               <MenuItem value="Aquile">Aquile</MenuItem>
               <MenuItem value="Castori">Castori</MenuItem>
               <MenuItem value="Panda">Panda</MenuItem>
               <MenuItem value="Pantere">Pantere</MenuItem>
            </Select>
         </FormControl>
         <List>
            {data.map((row, index) => {
               const listItemClass =
                  teamIndex !== null && row[teamIndex] > 0
                     ? classes.scored
                     : classes.unscored;

               return (
                  <span key={index}>
                     <ListItem>
                        <ListItemIcon>
                           <StarIcon className={listItemClass} />
                        </ListItemIcon>
                        <ListItemText
                           primary={row[0]}
                           className={classes.text}
                        />
                        <ListItemSecondaryAction className={classes.score}>
                           <ListItemText
                              primary={row[1]}
                              secondary="punti"
                              className={classes.text}
                           />
                           {/* {row[1]}
                           <br />
                           <small>punti</small> */}
                        </ListItemSecondaryAction>
                     </ListItem>
                     <Divider component="li" />
                  </span>
               );
            })}
         </List>
      </>
   );
}
