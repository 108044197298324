import React, { useEffect, useState } from 'react';
import {
   BrowserRouter as Router,
   HashRouter,
   Switch,
   Route,
   useHistory
} from 'react-router-dom';
import './App.css';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
   Container,
   CircularProgress,
   BottomNavigation,
   BottomNavigationAction,
   Box
} from '@material-ui/core';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import StarIcon from '@material-ui/icons/Star';
import AppContext from './AppContext';
import ActionList from './ActionList';
import Ranking from './Ranking';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import logo from './logo.svg';

function getData() {
   if (process.env.NODE_ENV !== 'development') return axios.get('/api.php');

   return Promise.resolve({
      status: 200,
      data: [
         ['Rifare il letto', '3', '', '1', '', '1'],
         ['Sparecchiare la tavola', '5', '1'],
         ['Bere una spremuta', '6', '', '', '1'],
         ['pregare per chi sta male', '7'],
         ['cucinare una cena per la famiglia', '10', '2', '2', '2', '2'],
         ['fotografare orione', '10', '', '', '2'],
         ['fotografare le alpi', '10', '', '1', '', '1'],
         ["preparare un'insalata di sprelle raccolte da te", '20', '1'],
         [
            'preparare dolcetti per la nonna chiusa in casa',
            '20',
            '1',
            '',
            '2',
            '3'
         ],
         ['fotografare il trebbia che entra nel Po', '10'],
         ["Salire in cima alla torre di Sant'Antonino", '30'],
         ['spedire una lettera a un altro scout', '5'],
         ['progettare una cosa innovativa per il campo', '30'],
         ['costruire un telefono a barattolo con il tuo vicino di casa', '100'],
         [
            'costruire una carrucola per non portare su la spesa dalle scale',
            '100'
         ],
         ['fare una serenata alla marta', '30'],
         ['fare una serenata al bri e sorbirsi i commenti', '200'],
         ['decorare la propria bicicletta', '30'],
         ['fotografare 5 specie di uccelli diversi', '50'],
         ["fare un ramoscello d'ulivo con la pasta di sale", '20'],
         ['rattoppare un paio di calze', '6'],
         ['cucire un patacco mancante sulla camicia', '10'],
         [
            'scrivere una frase motivazionale per terra con il gesso per i tuoi vicini di casa',
            '20'
         ],
         ['portare fuori la raccolta differenziata del tuo condominio', '5'],
         ['imparare il passo base del ballo liscio con un parente', '20'],
         ['guardare il Tg per 3 giorni di fila ', '5'],
         ['leggere un giornale', '10']
      ]
   });
}

const useStyles = makeStyles(theme => {
   return {
      navigation: {
         position: 'fixed',
         bottom: 0,
         left: 0,
         right: 0
      },
      body: {
         color: theme.palette.text.primary,
         paddingTop: theme.spacing(4),
         paddingBottom: theme.spacing(12)
      },
      logo: {
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
         height: '100vh',
         justifyContent: 'center',
         backgroundColor: theme.palette.background.default
      }
   };
});

function Navigation() {
   const history = useHistory();
   const classes = useStyles();
   const [navigation, setNavigation] = useState(history.location.pathname);
   return (
      <BottomNavigation
         value={navigation}
         onChange={(event, newValue) => {
            setNavigation(newValue);
            history.push(newValue);
         }}
         showLabels
         className={classes.navigation}
      >
         <BottomNavigationAction value="/" label="Sfide" icon={<StarIcon />} />
         <BottomNavigationAction
            value="/classifica"
            label="Classifica"
            icon={<AssistantPhotoIcon />}
         />
      </BottomNavigation>
   );
}

function App() {
   const [data, setData] = useState();
   const classes = useStyles();

   let theme = createMuiTheme({
      palette: {
         type: 'dark',

         primary: {
            main: '#F9CA06'
         },
         secondary: {
            main: '#EE323C'
         },
         background: {
            paper: '#435BA3',
            default: '#162767'
         },
         common: {
            black: '#CFE3F7',
            white: '#CFE3F7'
         },
         text: {
            primary: '#CFE3F7',
            secondary: '#CFE3F7',
            disabled: '#CFE3F7',
            hint: '#CFE3F7',
            icon: '#CFE3F7'
         }
      },
      typography: {
         // color: '#162767',
         fontSize: 16,
         fontFamily: [
            //'Spartan',
            //'Abel',
            // 'Oxanium',
            'Luckiest Guy',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
         ].join(', ')
      }
   });

   useEffect(() => {
      getData().then(response => {
         if (response.status === 200) {
            setData(response.data);
         }
      });
   }, []);

   const Logo = () => (
      <div className={classes.logo}>
         <img src={logo} width="300" />
      </div>
   );

   return (
      <AppContext.Provider
         value={{
            data
         }}
      >
         <ThemeProvider theme={theme}>
            {!data ? (
               <Logo />
            ) : (
               <Box
                  bgcolor="background.default"
                  style={{ minHeight: '100vh' }}
                  className={classes.body}
               >
                  <HashRouter>
                     <Container maxWidth="sm">
                        <Switch>
                           <Route path="/" component={ActionList} exact />
                           <Route path="/classifica" component={Ranking} />
                        </Switch>
                     </Container>

                     <Navigation />
                  </HashRouter>
               </Box>
            )}
         </ThemeProvider>
      </AppContext.Provider>
   );
}

export default App;
