import React, { useContext } from 'react';
import {
   List,
   ListItem,
   ListItemAvatar,
   Avatar,
   ListItemSecondaryAction,
   Divider,
   Typography,
   ListItemText
} from '@material-ui/core';
import AppContext from './AppContext';
import logo from './logo.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
   console.log(theme.palette.primary);
   return {
      logo: {
         textAlign: 'center',
         marginTop: theme.spacing(3)
      },
      text: {
         color: theme.palette.text.primary
      },
      number: {
         backgroundColor: theme.palette.primary.main
      }
   };
});

export default function Ranking() {
   const classes = useStyles();
   const appContext = useContext(AppContext);
   const { data } = appContext;
   const scores = { Aquile: 0, Castori: 0, Panda: 0, Pantere: 0 };
   data.forEach(row => {
      const rowScore = row[1];
      Object.keys(scores).forEach((team, index) => {
         const teamScore = parseInt(row[index + 2]);
         if (!isNaN(teamScore)) scores[team] += teamScore * rowScore;
      });
   });

   const teams = Object.keys(scores);
   teams.sort((a, b) => {
      const scoreA = scores[a];
      const scoreB = scores[b];
      if (scoreA > scoreB) return -1;
      if (scoreA < scoreB) return 1;
      return 0;
   });

   return (
      <>
         <Typography variant="h4" className={classes.text} align="center">
            Classifica
         </Typography>
         <List>
            {teams.map((team, index) => {
               return (
                  <span key={index}>
                     <ListItem>
                        <ListItemAvatar>
                           <Avatar className={classes.number}>
                              {index + 1}
                           </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={team} className={classes.text} />
                        <ListItemSecondaryAction>
                           <ListItemText
                              primary={scores[team]}
                              className={classes.text}
                           />
                        </ListItemSecondaryAction>
                     </ListItem>
                     <Divider />
                  </span>
               );
            })}
         </List>
         <div className={classes.logo}>
            <img src={logo} width="200" />
         </div>
      </>
   );
}
